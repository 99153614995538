
import { upload } from '../api/index'
import { Message } from 'element-ui';
/**
 * file:Objece 上传文件; fileType:Array 校验的格式; isVerificationType:Boolean 是否需要校验; fileSize:Number 需要限制的大小(单位M)
*/
let options = {
    fileType: [],
    isVerificationType: false,
    isVerificationSize: false,
    fileSize: 5
}

const fileUpload = function (file, option) {
    const type = file.raw.type ? file.raw.type : '';
    const size = file.raw.size / 1024 / 1024;
    options = { ...options, ...option }
    if (options.isVerificationType && !format(options.fileType, type)) {
        let tipText = options.fileType.join('、')
        Message.error(`上传文件格式不正确，请选择${tipText}格式的文件`);
        return;
    }
    if (options.isVerificationSize && options.fileSize < size) {
        Message.error(`上传文件不能大于${options.fileSize}M`);
        return;
    }
    return new Promise(function (reslove) {
        const formData = new FormData();
        formData.append("file", file.raw);
        upload(formData).then(json => {
            if (json.code === 200) {
                // 回显
                reslove(json.url)
            } else {
                this.$message.error(json.msg);
            }
        })
    })
}

//   验证格式是否正确
const format = function (typeArray, currentType) {
    return typeArray.some(e => {
        return e === currentType
    })
}


export default fileUpload
<template>
  <div class="content_box">
    <div class="content_box_title" @click="returnPage">账号设置</div>
    <div class="edit_box">
      <div class="edit_box_left">
        <div class="edit_box_left_img">
          <span>头像</span>
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :auto-upload="false"
            :on-change="upload"
          >
            <img
              :src="
                userInfo.icon
                  ? userInfo.icon
                  : require(`@/assets/img/defaultavatar.png`)
              "
              class="avatar"
            />
            <div class="edit_cover"></div>
            <span class="edit_img">修改</span>
          </el-upload>
        </div>
        <div class="edit_box_left_input">
          <span>昵称</span>
          <input
            type="text"
            class="edit_box_left_el_input"
            v-model="userInfo.nickName"
            placeholder="请输入昵称"
            maxlength="6"
          />
        </div>
        <div class="edit_box_left_input">
          <span>性别</span>
          <div class="edit_box_left_input_radio">
            <el-radio class="el-radio_class" v-model="radio" label="1"
              >男</el-radio
            >
            <el-radio class="el-radio_class" v-model="radio" label="2"
              >女</el-radio
            >
          </div>
        </div>
        <div class="edit_box_left_input">
          <span>手机</span>
          <input
            type="text"
            disabled
            class="edit_box_left_el_input dis_class"
            v-model="userInfo.mobile"
            placeholder="请输入昵称"
          />
        </div>
        <div class="edit_box_left_input" v-if="userInfo.loginName">
          <span>学号</span>
          <input
            type="text"
            class="edit_box_left_el_input dis_class"
            v-model="userInfo.loginName"
            placeholder="请输入昵称"
          />
        </div>
      </div>
      <div class="edit_box_right">
        <button class="edit_box_right_button" @click="depositBut">保存</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo, editUserInfo } from "@/api/personal";
import upload from "@/utils/uploadMethod";
export default {
  data() {
    return {
      radio: "",
      userInfo: {},
      img: "",
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    //获取个人信息
    getUserInfo() {
      getUserInfo().then((res) => {
        if (res.code == 200) {
          this.userInfo = res.data;
          switch (res.data.sex) {
            case 1:
              this.radio = "1";
              break;
            case 2:
              this.radio = "2";
              break;
            default:
              this.radio = "";
              break;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //保存
    depositBut() {
      this.userInfo.sex = this.radio;
      this.editUserInfo();
    },
    //上传头像
    async upload(file) {
      let option = {
        fileType: ["image/jpeg", "image/png", "image/bmp"],
        isVerificationType: true,
        isVerificationSize: true,
        fileSize: 5,
      };
      const fileUrl = await upload(file, option);
      this.userInfo.icon = fileUrl;
      this.editUserInfo();
    },
    //修改个人信息
    editUserInfo() {
      editUserInfo(this.userInfo).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getUserInfo();
          this.$emit("editUserInfo");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //返回上一页
    returnPage() {
      this.$router.push({
        path: "myStudy",
        query: { option: 5, path: "accountSettings" },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content_box {
  height: 748px;
}

.content_box_title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 60px;
  height: 60px;
  text-align: left;
  padding-left: 33px;
  border-bottom: 1px solid #eeeeee;
}

.edit_box {
  padding: 26px 21px 26px 31px;
}

.edit_box_left {
  margin-top: 7px;
  float: left;
  text-align: left;
  & > .edit_box_left_img {
    & > span {
      margin-top: 29px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 14px;
      margin-right: 33px;
      float: left;
    }
    & > .avatar-uploader {
      display: inline-block;
      vertical-align: top;
      position: relative;
      .avatar {
        width: 72px !important;
        height: 72px !important;
        border-radius: 50% !important;
        display: block;
      }
      .edit_img {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        line-height: 14px;
        position: absolute;
        top: 28px;
        left: 23px;
        display: none;
      }
    }
    & > .avatar-uploader:hover {
      .edit_cover {
        position: absolute;
        width: 100%;
        height: 72px;
        top: 0;
        background: #000000;
        opacity: 0.3;
        border-radius: 50%;
        display: block;
      }
      .edit_img {
        display: block;
      }
    }
  }
  & > .edit_box_left_input {
    margin-top: 24px;
    & > span {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 38px;
      margin-right: 33px;
      display: inline-block;
      vertical-align: top;
    }
    & > .edit_box_left_el_input {
      padding: 12px;
      width: 460px;
      height: 38px;
      background: #ffffff;
      border: 1px solid #e6e6e6;
      border-radius: 2px;
      display: inline-block;
      vertical-align: top;
    }
    & > .dis_class {
      background: #f6f6f6;
      color: #999999;
    }
    & > .edit_box_left_input_radio {
      line-height: 38px;
      display: inline-block;
      vertical-align: top;
      & > .el-radio_class {
        margin-top: 11px;
      }
    }
  }
}

.edit_box_right {
  float: right;
}

.edit_box_right_button {
  width: 82px;
  text-align: center;
  height: 33px;
  background: #0d79ff;
  border: 1px solid #e8e8e8;
  border-radius: 17px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  line-height: 33px;
}
</style>